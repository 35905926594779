<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <transversal-view-pdf
        v-if="pageInfo.type == 'transversal-view'"
        :condition="pageInfo.vesselType"
        :details="details"
        :vesselRoute="pageInfo.vesselRoute"
      />
      <bay-plan-pdf
        v-if="pageInfo.type == 'bay-plan'"
        :details="details"
        :currentBay="pageInfo.currentBay"
        :bayCode="pageInfo.bayCode"
        :vesselRoute="pageInfo.vesselRoute"
        :toDeckPos="pageInfo.positionsToDeck"
        :underDeckPos="pageInfo.positionsUnderDeck"
      />
      <vessel-plan-pdf
        v-if="pageInfo.type == 'vessel-plan'"
        :details="details"
        :vesselRoute="pageInfo.vesselRoute"
        :baysList="pageInfo.baysList"
        :unformatedBaysList="pageInfo.unformatedBaysList"
        :columnas="pageInfo.columnas"
        :generalCol="pageInfo.generalCol"
        :numberPerPage="pageInfo.numberPerPage"
      />
    </div>
  </transition>
</template>

<script>
import TransversalViewPdf from "@/pages/buque/pdf/transversal-view-pdf";
import BayPlanPdf from "@/pages/buque/pdf/bay-plan-pdf";
import VesselPlanPdf from "@/pages/buque/pdf/vessel-plan-pdf";
import debounce from 'lodash/debounce';

//Data
function data() {
  return {
    pageInfo: {
      vesselType: '',
      vesselId: '',
      vesselRoute: '',
      bayCode: '',
      positionsToDeck: [],
      positionsUnderDeck: [],
      currentBay: null,
    },
    details: null,
    docTitle: '',
  };
}

//Created
function created() {
  let info = JSON.parse(localStorage.getItem('pageInfo'));

  if(!info) {
    window.close();
    return;
  }

  this.pageInfo = {...this.pageInfo, ...info};

  this.getDataFromService(this.pageInfo.vesselId);

  localStorage.removeItem('pageInfo');
  
  window.addEventListener("beforeprint", function(event) {
    // document.title = `FULL CARGO PLAN`;
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

//Methods:
function getDataFromService(id) {
  this.$http.ejecutar('GET', 'VesselDetailReport-by-vessel', { VesselId: id })
  .then(response => {
    if(Array.isArray(response.data.data) && response.data.data.length == 1){
      this.details = {...response.data.data[0]};
    }
  }).catch( err => {
    console.log(err);
  });
}

//Computed:


export default {
  name: "Reporte-Buque-PDF",
  components: {
    TransversalViewPdf,
    BayPlanPdf,
    VesselPlanPdf,
  },
  data,
  created,
  updated: debounce(function () {
    this.$nextTick(() => {
      if(this.pageInfo.type == 'bay-plan')
        window.print();
    })
  }, 250),
  methods: {
    getDataFromService,
  },
  computed: {
  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>