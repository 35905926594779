<template>
  <div class="pdf-doc">
    <div class="pdf-view" v-if="hasDetailsInfo">
      <!-- Ficha Tecnica -->
      <section class="pdf">
        <section class="header">
          <div class="logo-container">
            <img :src="hasLogo" alt="SIGET" class="big-logo"/>
          </div>
          <div class="title-container">
            <strong class="mb-1">
              {{ titleFromCompany }}
            </strong>
            <strong class="mb-2">
              {{ companyLocation }}
            </strong>
          </div>
          <div class="logo-container">
            <img
              src="@/assets/image/LOGONEGRO-NARANJARecurso.svg"
              alt="SIGET"
              class="small-logo"
            />
          </div>
        </section>

        <section class="body">
          <div class="general-info">
            <div class="info-content">
              <div class="img-vessel">
                <img :src="hasVesselImg" alt="VESSEL" srcset="" />
              </div>
              <div class="title-vessel">
                <strong> {{ titleDataSheet }} </strong>
              </div>
              <div class="info-vessel">
                <div class="vessel">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>{{ $t('label.type') }}: </strong
                          >{{ getDato(details.TpVesselName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.country') }}: </strong
                          >{{ getDato(details.CountryName) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.IMO') }}: </strong>{{ getDato(details.Imo) }}
                        </td>
                        <td class="limit-field-30 p-0">
                          <table>
                            <tr>
                              <td>
                                <strong>MMSI: </strong
                                >{{ getDato(details.Mmsi) }}
                              </td>
                              <td>
                                <strong>CALL SIGN: </strong>
                                {{
                                  getDato(details.CallSign)
                                    ? getDato(details.CallSign)
                                    : "-"
                                }}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>LOA:</strong> {{ getDato(details.Loa) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.bowDraft') }}: </strong
                          >{{ getDato(details.DraftBow) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>{{ $t('label.summerDWT') }}: </strong
                          >{{ getDato(details.SummerDwt) }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.LWL') }}: </strong>{{ getDato(details.Lwl) }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{{ $t('label.sternDraft') }}: </strong
                          >{{ getDato(details.DraftStern) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>GROSS: </strong>{{ getDato(details.Gross) }}
                        </td>
                        <td class="limit-field-20">
                          <strong>TEUS: </strong>{{ getBayTeus }}
                        </td>
                        <td class="limit-field-30">
                          <strong>{{ $t('label.fuelOilCapacity') }}: </strong
                          >{{ getDato(details.FuelOilCapacity) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="bay-plan">
                  <div class="bay-plan-card">
                      <CRow align-horizontal="center">
                        <CCol sm="8" v-if="toDeckHasData">
                          <bay-grid
                            :col="colNum(false)"
                            :row="rowNum(false)"
                            :crossLine="isCrossLineTo"
                            :items="positionsToDeck"
                            showStatus
                            :showDashedNumbers="false"
                            :maxCol="responsiveCol(isCrossLineUnder)"
                          />
                        </CCol>
                      </CRow>
                      <hr class="dash">
                      <CRow align-horizontal="center">
                        <CCol sm="8" v-if="underDeckHasData">
                          <bay-grid
                          :col="colNum(true)"
                          :row="rowNum(true)"
                          :crossLine="isCrossLineUnder"
                          :items="positionsUnderDeck"
                          showStatus
                          showNumbersBottom
                          :showDashedNumbers="false"
                          :maxCol="responsiveCol(isCrossLineTo)"
                        />
                        </CCol>
                      </CRow>
                  </div>
                  <span class="teus-bayplan">TOTAL TEUS: {{getBayTeus}}</span>
                  <span class="baycode-bayplan">BAY: {{bayCode}}</span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="footer">
          <div class="corners-container">
            <p>{{ currentDateTime }}</p>
            <p class="usuarios">{{ loginName }}</p>
          </div>
          <div class="text-container">
            <p>{{ footerText }}</p>
          </div>
          <div class="corners-container">
            <p>{{ $t("label.page") }}: 1 {{ $t("label.of") }} {{ totalPages }}</p>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BayGrid from '@/pages/buque/bays/bay-grid';
import { BayMixin } from '@/_mixins/buque';
import DataStorage from "@/_helpers/localStorage";

//Data
function data() {
  return {
  };
}

//Created:
function created() {
  this.$i18n.locale = DataStorage.getLocale() || "es";
}

//Metodos:
function getDato(value) {
  if (!this.details) return "";

  return value ? value : "";
}

function formatNumber(number) {
  let format = Number.parseFloat(number).toFixed(2);
  format = format.replace('.', ',');

  return format.replace(/\d(?=(\d{3})+\,)/g, '$&.');
}

//Computeds:
function positionsToDeck() {
  return this.toDeckPos;
}
function positionsUnderDeck() {
  return this.underDeckPos;
}
function hasDetailsInfo() {
  return this.details ? true : false;
}
function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRoute
      ? this.user.LogoRoute.replace("Public/", "", null, "i")
      : ""
  }`;
}
function hasVesselImg() {
  return `${this.$store.getters["connection/getBase"]}${
    this.vesselRoute ? this.vesselRoute.replace("Public/", "", null, "i") : ""
  }`;
}
function footerText() {
  return "";
}
function currentDateTime() {
  let today = new Date();
  let month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1);
  let date =
    today.getDate() + "/" + month + "/" + today.getFullYear();
  let time = today.getHours() + ":" + today.getMinutes();

  return date + " " + time;
}
function titleFromCompany() {
  return this.user ? `${this.user.CompanyName} ${this.user.CompanyRif}` : "";
}
function companyLocation() {
  return this.user ? `${this.user.FiscalAddress}` : "";
}
function loginName() {
  return this.user ? `${this.$t("label.USUARIO")}: ${this.user.Login}` : "";
}
function titleDataSheet() {
  if (!this.details) return `BAYPLAN: `;

  let name = this.details.VesselName,
    imo = this.details.Imo;

  return `BAYPLAN ${this.bayCode}: ${name} - ${imo}`;
}
function totalPages() {
  return 1;
}
function getBayTeus() {
  if (!this.details) return "-";

  let found = this.details.Capacity20FeetJson.find((item) => item.BayCode == this.bayCode);
  if(!found) return "-";

  return found.Teus;
}

export default {
  name: "bay-plan-pdf",
  props: {
    details: {
      type: Object,
      default: null,
    },
    currentBay: {
      type: Object,
      default: null,
    },
    vesselRoute: {
      type: String,
      default: "",
    },
    bayCode: {
      type: String,
      default: "",
    },
    toDeckPos: {
      tyoe: Array,
      default: () => [],
    },
    underDeckPos: {
      tyoe: Array,
      default: () => [],
    }
  },
  mixins: [BayMixin],
  components: {
    BayGrid
  },
  data,
  created,
  methods: {
    getDato,
    formatNumber,
  },
  computed: {
    positionsToDeck,
    positionsUnderDeck,
    footerText,
    currentDateTime,
    titleFromCompany,
    companyLocation,
    hasLogo,
    hasVesselImg,
    loginName,
    hasDetailsInfo,
    titleDataSheet,
    totalPages,
    getBayTeus,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  watch: {
  },
};
</script>

<style scoped src="../../planificacion-estiba/archivos/pdf/pdf-styles.css"></style>
<style scoped>
.pdf-doc {
  color: #000 !important;
}
/* *************************************************** */
/* Canecera */
/* *************************************************** */
.pdf .header .title-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.pdf .header .title-container strong {
  margin-bottom: 0;
}

/* *************************************************** */
/* Ficha Tecnica */
/* *************************************************** */
.pdf .body .general-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.pdf .body .general-info .info-content {
  width: 95%;
  height: 600px;

  display: flex;
  flex-wrap: wrap;
  /* border: 1px solid #000; */
}
.pdf .body .general-info .info-content .img-vessel,
.pdf .body .general-info .info-content .title-vessel {
  height: 25%;

  background-color: #a6a6a6;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .img-vessel {
  width: 22%;
  border: 1px solid #000;
  border-right: 0;

  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}
.info-content .img-vessel img {
  height: 100%;
}
.info-content .title-vessel {
  width: 78%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  font-size: 1.1rem;
}
.pdf .body .general-info .info-content .info-vessel {
  width: 100%;
  height: 75%;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.info-content .info-vessel .vessel {
  width: 100%;
  /* height: 100%; */
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
}
.info-content .info-vessel .vessel > table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.9rem;
}
.vessel > table,
.vessel > table td {
  border: 1px solid #000;
}
.vessel > table td {
  padding: 2px 5px;
  width: 25%;
}
.limit-field-20 {
  width: 20% !important;
}
.limit-field-30 {
  width: 30% !important;
}
.info-content .info-vessel .vessel > table td > table {
  border-collapse: collapse;
  border-style: hidden;
  width: 100%;
  height: 100%;
}
.info-content .info-vessel .vessel > table td > table td {
  border: 1px solid black;
}

.info-content .info-vessel .bay-plan {
  border: 1px solid #000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}
.teus-bayplan {
  position: absolute;
  left: 82%;
  bottom: 10%;
}
.baycode-bayplan {
  position: absolute;
  left: 82%;
  bottom: 4%;
}

.dash{
  border: 0 none;
  border-top: 1px dashed #322f32;
  background: none;
  height:0;
  width: 80%;
  margin: 10px auto;
}
.bay-plan-card {
  width: 45%;
}
</style>